<template>
  <div class="text-left">
    <BaseHeader :title="'Additional Services'"></BaseHeader>

    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <section v-else>
      <div class=" mt-2 ">
        

        <div class="card orderlisting--card py-2">
        <section class=" order-listing-header-main p-3  ">  
          <div class=" d-flex align-items-center justify-content-between header-elements-inline pb-0">
            <!-- <h2 class="card-title text--dark--custom font-weight-black text-capitalize">
               {{ $route.params.status }} 
            </h2> -->
            <base-select-site
              class="p-2"
              @siteSelected="getAdditionalServices"
            ></base-select-site>
            <div class="header-elements">
              <button 
                color="primary"
                class="my-auto btn btn-add ml-2"
                size="small"
                @click.stop="crudAction"
                >New</button
              >
            </div>
          </div>
        
        </section>

        <div class=" mx-0  pref-table-custom ">
        
          <div  class=" order-listing-header    row  p-3 mx-sm-0 mx-1 ">  
            <div class="col-1 text-left text--dark--custom "> ID </div>
            <div class="col-4 text-left text--dark--custom ">Title</div>
            <div class="col-4 text-left text--dark--custom">Desc</div>
            <div class="col-2 text-left text--dark--custom"> Amount</div>
            <div class="col-1 text-left text--dark--custom">Action</div>
        </div>
      

        <div v-if="AdditionalServices.length > 0" class="">
        <div v-for="(item, index) in AdditionalServices" :key="index + 'ords'" class="order-listing-body row px-3 py-3 mx-sm-0 mx-1 ">  
            <div class="col-1 d-flex align-items-start justify-content-start text-left">	
              <span style="font-weight:500!important" class="px-2"
                >{{ item.id || "" }}</span>
              
            </div>
            <div class="col-4 text-left">
              <div style="font-weight:500!important" class="text-slate-500 text-xs whitespace-nowrap mt-0.5">{{ item.title || "" }}</div>
            </div>
            <div class="col-4 text-left">{{ item.desc || "" }}%</div>
            <div style="font-weight:500!important" class="col-2 text-left">  {{ item.cost || "" }} </div>
            
            <div class="col-1 text-left  ">
              <div class="d-flex flex-col">
                <v-btn
                  elevation="0"
                  class="white--text mr-1"
                  @click="
                    editMode(item);
                    edit = true;
                    $store.state.dialog = true;
                  "
                  fab
                  x-small
                >
                <v-icon scale="1.3" name="eye" color="black" />

                </v-btn>

                <v-btn
                  elevation="0"
                  class="white--text"
                  @click="deleteMode(item)"
                  fab
                  x-small
                >
                  <v-icon color="error">mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>
        </div>
        </div>

        </div>
        <div class="p-3 d-flex align-items-center justify-content-center w-100 "  v-if="AdditionalServices.length < 1">
          
              <strong class="order_empty  text-center" >No Writer Preferences.</strong>
          
        </div>
      </div>



      </div>

      <!-- delete dialog -->
      <v-dialog
        v-model="delete_dialog"
        class="text-left text-start"
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card :loading="deleting">
          <v-card-title class="text-h5"> Delete Service </v-card-title>
          <v-card-text>
            <div class="text-left">
              <p>Are you sure you want to delete?</p>

              <v-card-actions>
                <v-btn color="error darken-1" text @click="deleteService">
                  Yes, Delete
                </v-btn>
                <v-btn
                  color=""
                  text
                  @click="
                    delete_dialog = false;
                    activeService = {};
                  "
                >
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- delete dialog -->

      <!-- clients modal -->
      <v-dialog
        v-model="$store.state.dialog"
        class="text-left"
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card :loading="AdditionalServicesForm.busy">
          <v-card-title class="text-h5">
            {{
              edit
                ? `Update ${AdditionalServicesForm.level || ""}`
                : "New Writer Service"
            }}
          </v-card-title>
          <v-card-text>
            <!--  form -->
            <form
              class="flex-fill text-left"
              ref="AdditionalServicesForm"
              @submit.prevent="createAdditionalService"
            >
              <div class>
                <div class="mb-3">
                  <span class="d-block text-muted"
                    >All fields are required</span
                  >
                </div>

                <v-row>
                  <v-col cols="12">
                    <v-text-fieldx
                      dense
                      outlined
                      label="Title"
                      name="title"
                      type="text"
                      v-model="AdditionalServicesForm.title"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="AdditionalServicesForm"
                            class="v-messages theme--light error--text"
                            field="title"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      label="Description"
                      name="description"
                      type="text"
                      v-model="AdditionalServicesForm.desc"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="AdditionalServicesForm"
                            class="v-messages theme--light error--text"
                            field="desc"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      dense
                      outlined
                      label="Amount"
                      name="cost"
                      placeholder="Percentage to add to the total order cost"
                      min="0"
                      v-model="AdditionalServicesForm.cost"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="AdditionalServicesForm"
                            class="v-messages theme--light error--text"
                            field="cost"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-btn-toggle>
                  <v-btn
                    :disabled="AdditionalServicesForm.busy"
                    type="submit"
                    color="primary"
                    class="white--text"
                    >{{ edit ? "Update" : "Save" }}</v-btn
                  >
                  <v-btn
                    type="button"
                    @click="$store.state.dialog = !$store.state.dialog"
                    >Close</v-btn
                  >
                </v-btn-toggle>
              </div>
            </form>

            <!-- / form -->
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- clients modal -->
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Alerts from "../../../mixins/alerts";
import http from "../../modules/http.index";
export default {
  name: "AdditionalServices",
  computed: {
    ...mapState("additionalservices", [
      "AdditionalServicesForm",
      "loading",
      "AdditionalServices",
      "headers",
    ]),
    // ...mapState("clients", ["clients"]),
  },
  data() {
    return {
      countries: [],
      edit: false,
      delete_dialog: false,
      activeService: {},
      deleting: false,
    };
  },

  methods: {
    ...mapActions("additionalservices", ["getAdditionalServices"]),
    // ...mapActions("clients", ["getClients"]),
    createAdditionalService() {
      this.AdditionalServicesForm.description = this.AdditionalServicesForm.desc;
      this.AdditionalServicesForm.site_id = this.$store.state.site_id;
      this.edit
        ? this.AdditionalServicesForm.put(
            `${this.$baseUrl}/Setups/AdditionalServices/${this.AdditionalServicesForm.id}`
          )
            .then((res) => {
              this.$store.state.dialog = false;
              this.successNoti(res || `Service updated successfully`);

              this.getAdditionalServices();
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
              this.errorNoti(err || "Error while updating Service");
            })
        : this.AdditionalServicesForm.post(
            `${this.$baseUrl}/Setups/AdditionalServices/`
          )
            .then((res) => {
              this.$store.state.dialog = false;
              this.successNoti(res || `Service created successfully.`);
              this.getAdditionalServices();
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
              this.errorNoti(err || "Error while creating Service");
            });
    },

    editMode(style) {
      this.edit = true;
      this.AdditionalServicesForm.reset();
      this.$store.state.dialog = true;
      Object.assign(this.AdditionalServicesForm, { ...style });
      this.AdditionalServicesForm.cost = this.AdditionalServicesForm.cost
        .replace("+", "")
        .replace("%", "");
      this.AdditionalServicesForm.default =
        this.AdditionalServicesForm.default !== "0" ? true : false;
      this.AdditionalServicesForm.active =
        this.AdditionalServicesForm.active !== "0" ? true : false;
    },

    deleteMode(preference) {
      this.activeService = preference;
      this.delete_dialog = true;
    },

    deleteService() {
      this.deleting = true;
      http
        .delete(`Setups/AdditionalServices/${this.activeService.id}`)
        .then((res) => {
          this.delete_dialog = false;
          this.successNoti(res || `Service deleted successfully.`);
          this.getAdditionalServices();
        })
        .catch((err) => {
          this.errorNoti(err || `Error while deleting Service`);
        })
        .finally(() => (this.deleting = false));
    },

    crudAction() {
      if (this.$store.state.site_id) {
        this.edit = false;
        this.AdditionalServicesForm.reset();
        this.$store.state.dialog = true;
      } else {
        this.errorNoti("Please select a site to proceed");
      }
    },
  },
  async mounted() {
    // this.$store.state.site_id = this.$route.params.siteid

    if (this.$store.state.site_id) {
      await this.getAdditionalServices();
    }
  },
  mixins: [Alerts],
};
</script>
